export const WhatsAppUrl = 'https://wa.me';
export const WhatsAppNo = 9820130711;
export const WhataAppMessage = `Hi,

I came across an amazing platform that offers live online classes for Indian Arts.

You can learn Kathak or Hindustani Vocals. Courses are especially designed for Adults and Kids.

Select a course and Book a Trial class using this link: https://www.artfills.com/

Best regards`;

export const WhatsAppRescheduleMessage = `Hi, I missed my class and would like to reschedule it to another time. Could you please share the available slots for rescheduling? `;

export const discountType = 'percentage';

// export const API_URL = 'http://localhost:3001/api';
// export const GRAPHQL_API_URL = 'http://localhost:3001/graphql/api';

export const API_URL = 'https://api.artfills.com/api';

export const GRAPHQL_API_URL = 'https://api.artfills.com/graphql/api';

export const COURSES = {
  'kathak-for-adults': 'Kathak Adults',
  'kathak-for-kids': 'Kathak kids',
  'vocals-live-course': 'Vocals Adults',
  'vocals-for-kids': 'Vocal Kids'
};

export const RAZORPAY_KEY_ID = 'rzp_live_e9mftm02BJjbOC';

export const TEST_OTP = '6BJshC';

export const diwali30 = 'kD8YwN';

export const von25 = '7oUZYR';

export const BAD_REQUEST_EXCEPTION = 'Bad Request Exception';

export const Thank_you_route = '/booking-thank-you';
export const LOGIN_URL = '/login';
export const AUTH_ERROR_MESSAGE =
  'Your session has been expired! please login again';

export const NEW_ADMISSION_TITLE = 'New Admission';
export const NEW_ADMISSION_INSTALLMENT_TITLE = 'New Admission – Installment';
