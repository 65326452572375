'use client';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@material-tailwind/react';
import { ApolloProvider } from '@apollo/client';
import client from '@/graphql/graphql.config';
import { configureAppStore } from '@/store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import AppContextProvider from '@/contexts/AppContextProvider';
import useScrollToTop from '@/hooks/useScrollToTop';
import Script from 'next/script';
import Head from 'next/head';

const { store, persistor } = configureAppStore();

export default function App({
  children
}: Readonly<{
  children: NonNullable<JSX.Element>;
}>) {
  useScrollToTop();
  return (
    <>
      <Head>
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=818891915369732&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>

      {/* Google Tag Manager */}
      {/* <Script id="gtm-init" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${ANALYTICS_CONSTANTS.GTM_ID}');`}
      </Script> */}

      {/* Google Analytics 4 */}
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-8NMC2XVX2G');
          gtag('config', 'AW-802403638');
          allow_enhanced_conversions: true;
        `}
      </Script>

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-802403638"
        async
      />
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-8NMC2XVX2G"
        async
      />

      {/* Facebook Pixel */}
      <Script id="fb-pixel" strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s){
          if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '818891915369732');
          fbq('track', 'PageView');`}
      </Script>

      <Script src="https://player.vimeo.com/api/player.js" />

      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppContextProvider>
              <ThemeProvider>{children}</ThemeProvider>
            </AppContextProvider>
          </PersistGate>
        </ReduxProvider>
      </ApolloProvider>
    </>
  );
}
