'use client';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import Image from 'next/image';
import { Menu } from '@headlessui/react';
import { useRouter } from 'next/navigation';
import MenuItems from './MenuItems';
import Link from 'next/link';
import { deleteCookieByName } from '@/utils/cookies';
import { WhatsAppUrl, WhataAppMessage, WhatsAppNo } from '@/constants';
import './styles.scss';
import { isLoggedIn } from '@/utils';
import { useAppContext } from '@/contexts/AppContextProvider';
interface TopNavProps {
  className?: string;
}

const TopNav: React.FC<TopNavProps> = ({ className }) => {
  const {
    updateUserDispatch,
    updateCouponCodeDispatch,
    addUpdateCourseDispatch
  } = useAppContext();
  const route = useRouter();

  const sideMenuRef = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const openSideMenu = useCallback(() => {
    setMenuOpen((pre) => !pre);
  }, []);

  const sendMessage = () => {
    let url = `${WhatsAppUrl}/`;

    if (WhataAppMessage) {
      url += `?text=${encodeURI(WhataAppMessage)}`;
    }

    window.open(url, '_blank');
  };

  const sendSupportMessage = () => {
    let url = `${WhatsAppUrl}/${WhatsAppNo}`;
    if (WhataAppMessage) {
      url += `?text=${encodeURI(WhataAppMessage)}`;
    }
    window.open(url, '_blank');
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      sideMenuRef.current &&
      !sideMenuRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen, handleClickOutside]);

  return (
    <header
      className={`header fixed top-0 left-0 right-0 ${className} ${isLoggedIn() ? 'user-log-in' : ''}
       ${location.pathname === '/profile' ? 'header-black' : ''}
       ${location.pathname === '/checkout' ? 'header-black' : ''}
       `}
    >
      <div className="section-container-fluids">
        <nav className="mx-auto flex max-w-8xl items-center justify-between px-11 pb-2.5 pt-3 2xl:pt-3.5 ">
          <div className="flex lg:flex-1 logo-content items-center">
            <div className="logo-align-port w-32 2xl:w-40">
              <Link href="/" title="home">
                <Image
                  src="/logo.png"
                  alt="Artfills logo"
                  title="Artfills logo"
                  width={155}
                  height={54}
                  priority
                  className="logo-light"
                />
                <Image
                  src="/logo-dark.svg"
                  alt="Save upto devider"
                  title="Save upto devider"
                  width={155}
                  height={54}
                  className="logo-dark"
                />
              </Link>
            </div>

            <Menu as="div" className="relative inline-block text-left ml-6">
              <div>
                <Menu.Button className="uppercase inline-flex w-full items-center justify-center text-md gap-x-1.5 px-3 py-2 text-md text-white">
                  Courses
                  <Image
                    src="/dropdownIcon.png"
                    alt="Course Dropdown"
                    title="Course Dropdown"
                    width={22}
                    height={22}
                  />
                </Menu.Button>
              </div>
              <MenuItems />
            </Menu>
          </div>
          <div className="lg:flex lg:justify-end">
            {!isLoggedIn() ? (
              <Link
                href="/login"
                className="loginBtn py-1.5 px-9 rounded-lg text-xl uppercase text-white"
                title="login"
              >
                Log in
              </Link>
            ) : (
              <div
                className={`side-navigation-menu ${isMenuOpen ? 'menu-open' : ''}`}
                ref={sideMenuRef}
              >
                <span className="menu-toggle" onClick={() => openSideMenu()}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <aside className="side-bar-wrap">
                  <ul className="menu-item">
                    <li>
                      <Link href="/my-course" aria-current="page">
                        Enrolled courses
                      </Link>
                    </li>
                    <li>
                      <Link href="/profile">My account</Link>
                    </li>
                    <li>
                      <Link
                        title="Logout"
                        href="/"
                        onClick={() => {
                          deleteCookieByName('token');
                          if (addUpdateCourseDispatch) {
                            addUpdateCourseDispatch('');
                          }
                          if (updateUserDispatch) updateUserDispatch(null);
                          if (updateCouponCodeDispatch)
                            updateCouponCodeDispatch(null);
                          setMenuOpen(false);
                          route.push('/');
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                    <li className="whatsapp_share_header">
                      <button
                        title="Support"
                        onClick={sendSupportMessage}
                        className="recommendBtn p-3 rounded-xl text-white min-w-56 margin-bottom-set-50"
                      >
                        <span>Support</span>
                      </button>
                    </li>
                    <li className="whatsapp_share_header">
                      <button
                        className="recommendBtn p-3 rounded-xl text-white min-w-56 margin-bottom-set-50"
                        onClick={sendMessage}
                      >
                        <p>Recommend</p>
                        <p>Artfills to Friends and Family</p>
                      </button>
                    </li>
                  </ul>
                </aside>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default React.memo(TopNav);
